import React, { useState } from 'react';
import Button from '../Button';
import axios from 'axios';

import FormInputField from '../FormInputField/FormInputField';

import * as styles from './Contact.module.css';

const Contact = (props) => {
  const initialState = {
    name: '',
    // phone: '',
    email: '',
    message: '',
  };

  const [contactForm, setContactForm] = useState(initialState);
  const [formSent, setFormSent] = useState(false);

  const handleChange = (id, e) => {
    const tempForm = { ...contactForm, [id]: e };
    setContactForm(tempForm);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const response = {
      to: process.env.GATSBY_CONTACT_EMAIL,
      name: contactForm.name,
      email: contactForm.email,
      message: contactForm.message,
    };
    axios.post(
      'https://proforma-email-service.onrender.com/send-email',
      response
    );
    setFormSent(true);
    // scroll to top
    window.scrollTo(0, 0);
    // setContactForm(initialState);
  };

  if (formSent) {
    return (
      <div className={styles.root}>
        <h2>Thank you for your message!</h2>
        <p>We will get back to you as soon as possible.</p>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <h4>Send Us A Message</h4>
        <p>
          Please use the form below to send us a message and we will get back
          to.
        </p>
        <p>We look forward to hearing from you.</p>
      </div>

      <div className={styles.section}>
        <h4>Phone</h4>
        <p>+44 (0)7496780051</p>
        <p>Monday to Friday - 10am-6pm GMT</p>
      </div>

      <div className={styles.section}>
        <h4>Email</h4>
        <p>
          You can email our Customer Service team at info@marionancientart.com
          or via the contact form below:
        </p>
      </div>

      <div className={styles.contactContainer}>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className={styles.contactForm}>
            <FormInputField
              id={'name'}
              value={contactForm.name}
              handleChange={(id, e) => handleChange(id, e)}
              type={'text'}
              labelName={'Full Name'}
              required
            />
            {/* <FormInputField
              id={'phone'}
              value={contactForm.phone}
              handleChange={(id, e) => handleChange(id, e)}
              type={'number'}
              labelName={'Phone Number'}
              required
            /> */}
            <FormInputField
              id={'email'}
              value={contactForm.email}
              handleChange={(id, e) => handleChange(id, e)}
              type={'email'}
              labelName={'Email'}
              required
            />
            <div className={styles.commentInput}>
              <FormInputField
                id={'message'}
                value={contactForm.message}
                handleChange={(id, e) => handleChange(id, e)}
                type={'textarea'}
                labelName={'Comments / Questions'}
                required
              />
            </div>
          </div>
          <Button
            className={styles.customButton}
            level={'primary'}
            type={'buttonSubmit'}
          >
            submit
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
