import React, { useEffect, useState } from 'react';
import * as styles from './Policy.module.css';
import { graphql, useStaticQuery } from 'gatsby';

const Policy = (props) => {
  const [terms, setTerms] = useState('');
  const termsData = useStaticQuery(graphql`
    query {
      allGoogleTermsSheet {
        edges {
          node {
            content
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (termsData?.allGoogleTermsSheet?.edges[0]?.node?.content) {
      setTerms(termsData?.allGoogleTermsSheet?.edges[0]?.node?.content);
    }
  }, [termsData]);

  return (
    <div className={styles.root}>
      <div className={styles.section}>
        <div dangerouslySetInnerHTML={{ __html: terms }} />
      </div>
    </div>
  );
};

export default Policy;
